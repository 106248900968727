import {
  Alert,
  ColumnLayout,
  FormField,
  Select,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { useEffect, useState } from "react";
import { AppStreamSdkProps } from "../../../../types/Toolbar";
import { Trans, useTranslation } from "react-i18next";
import useMicrophoneDevices from "../../../../hooks/useMicrophoneDevices";
import { useMediaDeviceStore } from "../../../../hooks/useMediaDeviceStore";
import useCameraDevices from "../../../../hooks/useCameraDevices";

export const MicrophoneSelector = (): JSX.Element => {
  const { t } = useTranslation();

  const [
    isMicrophonePermissionAllowed,
    setIsMicrophonePermissionAllowed,
  ] = useState<boolean>(false);

  useEffect(() => {
    checkMicrophonePermission();
  }, []);

  const checkMicrophonePermission = async () => {
    try {
      const result = await navigator.permissions.query({
        name: "microphone" as PermissionName,
      });
      setIsMicrophonePermissionAllowed(result.state === "granted");

      // Listen for changes in permission state
      result.onchange = () => {
        setIsMicrophonePermissionAllowed(result.state === "granted");
      };
    } catch (error) {
      console.error("Error checking microphone permission:", error);
      setIsMicrophonePermissionAllowed(false);
    }
  };

  const microphonePermissionNotAllowedNotification = (
    <Alert
      statusIconAriaLabel="Warning"
      type="warning"
      data-testId="microphonePermissionAlert"
      header={t(
        "toolbar.item.preference.content.tab.audioVideo.microphone.permissionAlert.header"
      )}
    >
      <Trans
        i18nKey={
          "toolbar.item.preference.content.tab.audioVideo.microphone.permissionAlert.content"
        }
      />
    </Alert>
  );

  const microphoneOptions = useMicrophoneDevices().map((option) => {
    return { label: option.label, value: option.deviceId };
  });

  const selectedMicrophone = useMediaDeviceStore(
    (state) => state.selectedMicrophone
  );
  const setSelectedMicrophone = useMediaDeviceStore(
    (state) => state.setSelectedMicrophone
  );
  const [selectedOption, setSelectedOption] = React.useState({
    label: selectedMicrophone ? selectedMicrophone.label : undefined,
    value: selectedMicrophone ? selectedMicrophone.deviceId : undefined,
  });

  return (
    <FormField
      label={t(
        "toolbar.item.preference.content.tab.audioVideo.microphone.label"
      )}
      description={t(
        "toolbar.item.preference.content.tab.audioVideo.microphone.description"
      )}
      data-testid="microphoneFormField"
    >
      {isMicrophonePermissionAllowed ? (
        <Select
          selectedOption={selectedOption}
          onChange={({ detail }) => {
            setSelectedOption({
              label: detail.selectedOption.label,
              value: detail.selectedOption.value,
            });
            setSelectedMicrophone({
              label: detail.selectedOption.label,
              deviceId: detail.selectedOption.value,
            });
          }}
          options={microphoneOptions}
          placeholder={t(
            "toolbar.item.preference.content.tab.audioVideo.microphone.placeholder"
          )}
          data-testid="microphoneSelectMenu"
        />
      ) : (
        microphonePermissionNotAllowedNotification
      )}
    </FormField>
  );
};

export const CameraSelector = (): JSX.Element => {
  const { t } = useTranslation();

  const [isCameraPermissionAllowed, setIsCameraPermissionAllowed] = useState<
    boolean
  >(false);

  useEffect(() => {
    checkCameraPermission();
  }, []);

  const checkCameraPermission = async () => {
    try {
      const result = await navigator.permissions.query({
        name: "camera" as PermissionName,
      });
      setIsCameraPermissionAllowed(result.state === "granted");

      // Listen for changes in permission state
      result.onchange = () => {
        setIsCameraPermissionAllowed(result.state === "granted");
      };
    } catch (error) {
      console.error("Error checking camera permission:", error);
      setIsCameraPermissionAllowed(false);
    }
  };

  const cameraPermissionNotAllowedNotification = (
    <Alert
      statusIconAriaLabel="Warning"
      type="warning"
      data-testId="cameraPermissionAlert"
      header={t(
        "toolbar.item.preference.content.tab.audioVideo.camera.permissionAlert.header"
      )}
    >
      <Trans
        i18nKey={
          "toolbar.item.preference.content.tab.audioVideo.camera.permissionAlert.content"
        }
      />
    </Alert>
  );

  const cameraDevices = useCameraDevices();
  const cameraOptions = (cameraDevices || []).map((option) => {
    return { label: option.label, value: option.deviceId };
  });

  const selectedWebcam = useMediaDeviceStore((state) => state.selectedWebcam);
  const setSelectedWebcam = useMediaDeviceStore(
    (state) => state.setSelectedWebcam
  );

  const [selectedOption, setSelectedOption] = React.useState({
    label: selectedWebcam ? selectedWebcam.label : undefined,
    value: selectedWebcam ? selectedWebcam.deviceId : undefined,
  });

  return (
    <FormField
      label={t("toolbar.item.preference.content.tab.audioVideo.camera.label")}
      description={t(
        "toolbar.item.preference.content.tab.audioVideo.camera.description"
      )}
      data-testid="cameraFormField"
    >
      {isCameraPermissionAllowed ? (
        <Select
          selectedOption={selectedOption}
          onChange={({ detail }) => {
            setSelectedOption({
              label: detail.selectedOption.label,
              value: detail.selectedOption.value,
            });
            setSelectedWebcam({
              label: detail.selectedOption.label,
              deviceId: detail.selectedOption.value,
            });
          }}
          options={cameraOptions}
          placeholder={t(
            "toolbar.item.preference.content.tab.audioVideo.camera.placeholder"
          )}
          data-testid="cameraSelectMenu"
        />
      ) : (
        cameraPermissionNotAllowedNotification
      )}
    </FormField>
  );
};

/**
 * Preferences - Audio & Video tab content
 */
export const AudioVideoTabContent = ({
  appStreamSdk,
}: AppStreamSdkProps): JSX.Element => {
  return (
    <ColumnLayout columns={2} data-testId="audio-video-tab-content">
      <SpaceBetween direction="vertical" size="m">
        <MicrophoneSelector data-testId="microphone-selector-audioVideoTab" />
      </SpaceBetween>
      <SpaceBetween direction="vertical" size="m">
        <CameraSelector data-testId="camera-selector-audioVideoTab" />
      </SpaceBetween>
    </ColumnLayout>
  );
};
