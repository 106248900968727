import { useEffect, useState } from "react";

const useMicrophoneDevices = () => {
  const [microphoneDevices, setMicrophoneDevices] = useState([]);

  const getMicrophoneDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const microphoneDevices = devices
        .filter((device) => device.kind === "audioinput")
        .map((device) => ({
          label: device.label,
          deviceId: device.deviceId,
        }));
      setMicrophoneDevices(microphoneDevices);
    } catch (error) {
      console.error("Error enumerating microphone devices: ", error);
    }
  };

  useEffect(() => {
    getMicrophoneDevices();

    navigator.mediaDevices.addEventListener(
      "devicechange",
      getMicrophoneDevices
    );

    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        getMicrophoneDevices
      );
    };
  }, []);

  return microphoneDevices;
};

export default useMicrophoneDevices;
