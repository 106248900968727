import { useEffect, useState } from "react";

const useCameraDevices = () => {
  const [cameraDevices, setCameraDevices] = useState([]);

  const getCameraDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const cameraDevices = devices
        .filter((device) => device.kind === "videoinput")
        .map((device) => ({
          label: device.label,
          deviceId: device.deviceId,
        }));
      setCameraDevices(cameraDevices);
    } catch (error) {
      console.error("Error enumerating camera devices: ", error);
    }
  };

  useEffect(() => {
    getCameraDevices();

    navigator.mediaDevices.addEventListener("devicechange", getCameraDevices);

    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        getCameraDevices
      );
    };
  }, []);
  return cameraDevices;
};

export default useCameraDevices;
